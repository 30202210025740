export default {
  TITLE: 'title',
  ADD_USER: 'addUser',
  BACK: 'back',
  DAILY_MAP: 'daily-map',
  EDUCATION: 'education',
  HELP: 'help',
  LOGOUT: 'logout',
  MAP: 'map',
  PAYMENT: 'payment',
  SETTINGS: 'settings',
  SETTINGS_COUPON: 'settings-coupon',
  SETTINGS_DAILY_MAP: 'settings-daily-map',
  SETTINGS_APP: 'settings-app',
  SETTINGS_USER: 'settings-user',
  SHOP: 'shop',
  SPEECH_SOUND: 'speech-sound',
  SPEECH_THERAPIST_BACK: 'speech-therapist-back',
  SPEECH_THERAPIST_STUDENT: 'speech-therapist-student',
  STATISTICS: 'statistics',
  LICENSE: 'license',
  FASTSPRING_WEB_STORE: 'buy',
  KOKOLINGO_EKSPERT: 'kokolingo-ekspert',
};
